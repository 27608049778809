import { createRouter, createWebHashHistory } from 'vue-router'
import MainView from '@/components/MainView.vue';
import BeginnerGuide from '@/components/Beginner.vue';
import RegulationGuide from '@/components/Regulation.vue';
import TemporaryBooking from '@/components/TemporaryBooking.vue';
import LoginMyPage from '@/components/LoginMyPage.vue';
import MyPage from '@/components/MyPage.vue';
import MemberRegistration from '@/components/MemberRegistration.vue';
import ContactUs from '@/components/ContactUs.vue';
import BattleRecord from '@/components/BattleRecord.vue';
import ViewQrCode from '@/components/ViewQrCode.vue';
import SettingKillCounts from '@/components/SettingKillCounts.vue';
import Nerf from '@/components/Nerf.vue';
import ReserveCalendar from '@/components/ReserveCalendar.vue';
import ReserveSelect from '@/components/ReserveSelect.vue';
import ReserveList from '@/components/ReserveList.vue';
import EditReserve from '@/components/EditReserve.vue';
import TeamList from '@/components/TeamList.vue';
import Price from '@/components/Price.vue';
import PhotoGallery from '@/components/PhotoGallery.vue';
import RankingList from '@/components/RankingList.vue';

const routes = [
    {
      path: '/',
      name: 'main',
      component: MainView,
      meta: { backgroundColor: 'white' }
    },
    {
      path: '/beginner',
      name: 'beginner',
      component: BeginnerGuide,
      meta: { backgroundColor: 'white' }
    },
    {
      path: '/regulation',
      name: 'regulation',
      component: RegulationGuide,
      meta: { backgroundColor: 'white' }
    },    
    {
      path: '/login-my-page',
      name: 'loginMyPage',
      component: LoginMyPage,
      meta: { backgroundColor: 'black' }
    },
    {
      path: '/my-page',
      name: 'MyPage',
      component: MyPage,
      meta: { backgroundColor: 'black' }
    },
    {
      path: '/temporary-booking/:selectedDate?/:fromMyPage?',
      name: 'temporaryBooking',
      component: TemporaryBooking,
      meta: { backgroundColor: 'black' },
      props: route => ({ selectedDate: route.params.selectedDate, fromMyPage:  route.params.fromMyPage}),
    },
    {
      path: '/edit-reserve/:nfldno?/:drsvdt?/:vtel?',
      name: 'editReserve',
      component: EditReserve,
      meta: { backgroundColor: 'black' },
      props: route => ({ nfldno: route.params.nfldno, drsvdt: route.params.drsvdt,vtel: route.params.vtel}),
    },
    {
      path: '/member-registration',
      name: 'memberRegistration',
      component: MemberRegistration,
      meta: { backgroundColor: 'black' }
    },
    {
      path: '/contact-us',
      name: 'contactUs',
      component: ContactUs,
      meta: { backgroundColor: 'black' }
    },
    {
      path: '/battle-record',
      name: 'BattleRecord',
      component: BattleRecord,
      meta: { backgroundColor: 'black' }
    },
    {
      path: '/view-qr-code',
      name: 'ViewQrCode',
      component: ViewQrCode,
      meta: { backgroundColor: 'black' }
    },
    {
      path: '/setting-kill-counts',
      name: 'SettingKillCounts',
      component: SettingKillCounts,
      meta: { backgroundColor: 'black' }
    },
    {
      path: '/reserve-select',
      name: 'ReserveSelect',
      component: ReserveSelect,
      meta: { backgroundColor: 'black' }
    },
    {
      path: '/reserve-list',
      name: 'ReserveList',
      component: ReserveList,
      meta: { backgroundColor: 'black' }
    },
     {
      path: '/team-list',
      name: 'TeamList',
      component: TeamList,
      meta: { backgroundColor: 'black' }
    },
    {
      path: '/nerf',
      name: 'NerfView',
      component: Nerf,
      meta: { backgroundColor: 'white' }
    },
    {
      path: '/price',
      name: 'PriceView',
      component: Price,
      meta: { backgroundColor: 'white' }
    },
    {
      path: '/photo-gallery',
      name: 'PhotoGallery',
      component: PhotoGallery,
      meta: { backgroundColor: 'white' }
    },
    {
      path: '/calendar/:fromMyPage?',
      name: 'ReserveCalendar',
      component: ReserveCalendar,
      meta: { backgroundColor: 'white' },
      props: route => ({ fromMyPage: route.params.fromMyPage })
    },
    {
      path: '/ranking-list',
      name: 'RankingList',
      component: RankingList,
      meta: { backgroundColor: 'black' }
   },   
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }; // 遷移時に常に最上部にスクロールする
  }
});

router.beforeEach((to, from, next) => {
  // ページ遷移時に背景色を変更する
  document.documentElement.style.backgroundColor = to.meta.backgroundColor || ''; // デフォルトの背景色を使用するか、各ページの指定された背景色を使用する
  next();
});

export default router;
