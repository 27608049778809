import APIClient from "@/modules/api";
import { API_STATUS } from "@/modules/constants";

// 日付をyyyyMMdd形式の数値に変換する関数
export function convertDateToNumber(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return year * 10000 + month * 100 + day;
}

export function pad(num) {
      return num.toString().padStart(2, '0');
}

export function generateRandomString(length) {
      const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let randomString = '';

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters.charAt(randomIndex);
      }

      return randomString;
  }

export function formatPhoneNumberToE164(phoneNumber, country = 'JP') {
  // 日本の国コードは +81
  const countryCode = country == 'JP' ? "+81" : "";
  
  // ハイフンを取り除く
  let cleaned = phoneNumber.replace(/-/g, "");
  
  // 先頭の "0" を削除
  if (cleaned.startsWith("0")) {
    cleaned = cleaned.substring(1);
  }

  // E.164形式に変換
  const e164PhoneNumber = countryCode + cleaned;
  
  return e164PhoneNumber;
}

export function getQueryParam(name) {
      const hash = window.location.hash;
      const params = new URLSearchParams(hash.split("?")[1]);
      return params.get(name);
}
  
export async function checkLoginInfo(vid, login_hash, login_time) {
  try {
    const response = await APIClient.get("verify_login_hash.php", {
      vid,
      login_hash,
      login_time,
    });
    return response.Status == API_STATUS.NORMAL;
  } catch (error) {
    return false;
  }
}

export function logout() {
  localStorage.removeItem("sessionUserId");
  localStorage.removeItem("login_hash");
  localStorage.removeItem("login_time");
  window.location.href = "/#/login-my-page";
}